<template>
  <div class="focus">
    <v-container
      id="extended-tables-view"
      fluid
      tag="section"
    >
      <material-card
        icon="far fa-star"
        icon-small
        color="primary"
        :title="$t('pageTitles.Focus')"
      >
        <v-card-text>
          <v-row>
            <v-col
              v-for="(col, index) in cols"
              :key="`focus-column-${index}`"
              cols="3"
            >
              <div class="d-flex justify-center mb-3">
                <v-btn
                  color="success"
                  small
                  @click="initiateNewFocus(index)"
                >
                  {{ $t('new') }}
                </v-btn>
              </div>
              <span v-if="!col || !col.length">
                {{ $t('no_focus_on_column') }}
              </span>
              <template v-else>
                <FocusCard
                  v-if="currentElement(index)"
                  :focus="currentElement(index)"
                  @editing="initiateFocusEdit(currentElement(index))"
                  @deleted="focusDeletedHandler"
                />
                <div
                  v-if="otherElements(index).length"
                  class="d-flex justify-center mb-2"
                >
                  {{ $t('previous_foci') }}
                </div>
                <FocusCard
                  v-for="focus in otherElements(index)"
                  :key="`focus-column-${index}-focus-${focus.id}`"
                  :focus="focus"
                  @editing="initiateFocusEdit(focus)"
                  @deleted="focusDeletedHandler"
                />
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </material-card>
    </v-container>
    <v-dialog
      v-model="showFocusForm"
      width="500"
    >
      <FocusForm
        v-if="showFocusForm"
        :focus="focusToEdit"
        :column="focusColumn"
        @closed="closeFocusForm"
        @updated="focusUpdatedHandler"
        @created="focusCreatedHandler"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FocusCard from '../components/FocusCard';
import FocusForm from '../components/FocusForm';
export default {
  name: 'FocusView',

  components: { FocusCard, FocusForm },

  data: () => ({
    cols: [[], [], [], []],
    showFocusForm: false,
    focusToEdit: null,
    focusColumn: null,
    dragabbles: [],
  }),

  computed: {
    ...mapGetters({
      foci: 'focus/getFoci',
    }),
  },

  created() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.$store.dispatch('focus/fetchFoci').then(() => this.fillCols());
    },
    fillCols() {
      this.cols = this.foci
        .sort((a, b) => a.published_at <= b.published_at ? 1 : -1)
        .reduce((acc, item) => {
          const column = item.column - 1;
          acc[column].push(item);
          return acc;
        }, [[], [], [], []]);
    },
    currentElement(col) {
      if (!this.cols || !this.cols[col] || !this.cols[col][0]) {
        return null;
      }
      return this.cols[col][0];
    },
    otherElements(col) {
      if (!this.currentElement(col)) {
        return [];
      }
      return this.cols[col].filter(focus => focus.id !== this.currentElement(col).id);
    },
    initiateNewFocus(column) {
      this.focusToEdit = null;
      this.focusColumn = column + 1;
      this.showFocusForm = true;
    },
    initiateFocusEdit(focus) {
      this.focusToEdit = focus;
      this.focusColumn = focus.column;
      this.showFocusForm = true;
    },
    closeFocusForm() {
      this.focusToEdit = null;
      this.focusColumn = null;
      this.showFocusForm = false;
    },
    focusUpdatedHandler() {
      this.refreshData();
    },
    focusCreatedHandler() {
      this.refreshData();
    },
    focusDeletedHandler() {
      this.refreshData();
    },
  },
};
</script>

<style scoped>
>>> .v-dialog {
  overflow-y: visible;
}
</style>
